import React, { useState } from "react";
import "./Tile.css";
import Dialog from "./Dialog";

function Tile({ title, tile_description, long_description }) {
  const [isHovered, setIsHovered] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setShowDialog(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setShowDialog(false);
  };

  return (
    <div
      className={`tile ${isHovered ? "tile-hovered" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h2>{title}</h2>
      {/* <p>{tile_description}</p> */}
      {showDialog && <Dialog title={title} description={long_description} />}
    </div>
  );
}

export default Tile;
