// Header.js
import React from "react";
import "./Header.css";
import profilePic from "./profile_pic.jpg"; // import your image file

const Header = ({ myname, myemail, myphone, occupation }) => {
  return (
    <header className="header">
      <img src={profilePic} alt="Profile" className="profile-pic" />
      <div className="header-text">
        <h1>{myname}</h1>
        <h2>{occupation}</h2>
        <p>Manteca, CA</p>
        <p>
          <a href={`mailto:${myemail}`} style={{ color: "#f0f0f0" }}>
            {myemail}
          </a>
        </p>
        <p>{myphone} [Pacific]</p>
        <p>
          With 8+ years of experience, I write pragmatic code that solves
          real-world problems.
        </p>
        <p>I've turned my passion for coding into a thriving career,</p>
        <p>
          always keen to write new chapters in my life and explore new tech
          territories!
        </p>
      </div>
    </header>
  );
};

export default Header;
