import ReactMarkdown from "react-markdown";
import React from "react";
import "./Dialog.css";

function Dialog({ title, description }) {
  return (
    <div className="dialog">
      <h2>{title}</h2>
      <ReactMarkdown>{description}</ReactMarkdown>
    </div>
  );
}

export default Dialog;
